const initialState = {
  pages: [],
  content: [],
  carousel: [],
  hero: [],
  contactForm: [],
  productList: [],
  socialMedia: [],
}

// ACTION TYPES
const INIT_PAGES = "INIT_PAGES";
const INIT_CONTENT = "INIT_CONTENT";
const INIT_CAROUSEL = "INIT_CAROUSEL";
const INIT_HERO = "INIT_HERO";
const INIT_CONTACT_FORM = "INIT_CONTACT_FORM";
const INIT_PRODUCT_LIST = "INIT_PRODUCT_LIST";
const INIT_SOCIAL_MEDIA = "INIT_SOCIAL_MEDIA";

// ACTIONS
export const initPages = (pages) => ({ type: INIT_PAGES, pages });
export const initContent = (content) => ({ type: INIT_CONTENT, content });
export const initCarousel = (carousel) => ({ type: INIT_CAROUSEL, carousel });
export const initHero = (hero) => ({ type: INIT_HERO, hero });
export const initContactForm = (contactForm) => ({ type: INIT_CONTACT_FORM, contactForm });
export const initProductList = (productList) => ({ type: INIT_PRODUCT_LIST, productList });
export const initSocialMedia = (socialMedia) => ({ type: INIT_SOCIAL_MEDIA, socialMedia });

// REDUCER
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_PAGES:
      return { ...state, pages: action.pages };
    case INIT_CONTENT:
      return { ...state, content: action.content };
    case INIT_CAROUSEL:
      return { ...state, carousel: action.carousel };
    case INIT_HERO:
      return { ...state, hero: action.hero };
    case INIT_CONTACT_FORM:
      return { ...state, contactForm: action.contactForm };
    case INIT_PRODUCT_LIST:
      return { ...state, productList: action.productList };
    case INIT_SOCIAL_MEDIA:
      return { ...state, socialMedia: action.socialMedia };
    default:
      return state;
  }
}

export default Reducer;
